exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ota-yhteytta-tsx": () => import("./../../../src/pages/ota-yhteytta.tsx" /* webpackChunkName: "component---src-pages-ota-yhteytta-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-pulpetti-tsx": () => import("./../../../src/pages/pulpetti.tsx" /* webpackChunkName: "component---src-pages-pulpetti-tsx" */),
  "component---src-pages-ritta-tsx": () => import("./../../../src/pages/ritta.tsx" /* webpackChunkName: "component---src-pages-ritta-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

